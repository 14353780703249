.works {
  display: flex;
    flex-direction: row;
    align-items: stretch; /* Ensure items stretch to the same height */
    justify-content: center;
    margin: auto;
    line-height: 1.6;
    gap: 20px;
    height: auto; /* Dynamically fit the content */
    min-height: 40vh; /* Ensure a minimum height for larger screens */
    margin-bottom: 5vh;
}

.works-logo {
    margin-left: -30px;
    width: 100px;
    height: auto;
  }

  .works p{
    font-family: Montserrat;
    font-size: medium;
    color:rgb(0, 0, 65) ;
    text-align: left;
    line-height: 1.6;
  }
  .works h1{
    font-family: Montserrat;
    font-size: large;
    font-weight: bold;
    color:rgb(0, 0, 65) ;
    text-align: left;
    text-transform: uppercase;
  }
  .works h2{
    font-family: Montserrat;
    font-size: 50px;
    font-weight: bold;
    color:rgb(0, 0, 65) ;
    text-align: left;
  }

  @media (max-width: 768px) {
    .works {
      flex-direction: column; /* Stack items vertically */
      gap: 20px; /* Maintain spacing between items */
    }
  
    .features-item {
      width: 100%; /* Full width for smaller screens */
    }
  }  