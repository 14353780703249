.video-container{
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: auto;
    width: 50%;
    height: 400px;
    padding: 20px 0 20px 0;
}
.video{
    width: 100%;
    height: 100%;
}
