.header{
    font-size: 28px;
	font-family: Montserrat;

    font-weight: bold;
    color: #ee487d;
    text-align: center;
    margin-bottom: 50px;
}
.patientCardsDiv{
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
    /* width: 1000px; */
    margin-left: 50px;
    /* justify-content: center; */
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 4fr)); */
  
  }
  .blog1{
    height: 1000px;
    width: 1000px;
  }
.cardContainer {
    width: auto;
    height: auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15), 4px 0px 25px rgba(0, 0, 0, 0.15);
    padding: 10px;
    cursor: pointer;
    padding: 40px;
    margin: 30px;
    background: #ffffff;
    border-radius: 15px;
  }
  .blogContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15), 4px 0px 25px rgba(0, 0, 0, 0.15);
    padding: 10px;
    cursor: pointer;
    padding: 40px;
    margin: 30px;
    background: #ffffff;
    border-radius: 15px;
  }


  /* .nameDet {
    display: flex;
    align-items: center;
  } */

  .img{
    width: 450px;
    height: 550px;
    display: flex;
    justify-content: center;
    align-items: center;

    /* border-radius: 15px; */
  }
  .CardsDiv {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    align-items: center;
    margin-top: 50px;
    margin-left: 22%;
}
.figure1and2{
  display: flex;
  padding-top:"15px";
  justify-content: center;
  align-items: center;
  width: 100%;
}
.img2{
  width: 700px;
  padding-right: 125px ;
}
.peaksImg{
     width: 700px;
    height: auto;
    /* margin-left:"100px"; */
}
  @media (max-width: 767px){
    .blog1{
      height: 1000px;
      width: 60%;
    }
    .blogContainer{
      /* padding-right: 0px; */
      width: 60%;
      height: 1000px;
    }
   

    .cardContainer{
        width: 80%;
        margin-right: 75px;
        height: 80%;
    }
    .CardsDiv{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: auto;
    }
    .img{
        width: 200px;
        height: 250px;
    }
    .header{
        font-size: 16px;
        font-family: Montserrat;
    
        font-weight: bold;
        color: #ee487d;
        text-align: center;
        margin-bottom: 50px;
    }
}
@media (max-width: 956px){
  .blog1{
    height: 1000px;
    width: 90%;
  }
  .blogContainer{
    /* padding-right: 0px; */
    width: 90%;
    height: 1000px;
  }
  .imageContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 100px;
    width:50%;
}
.img2{
  padding-left: 200px;
}
    .peaksImg{
    
        width:100%;
        margin-left:"100px";
    }
    .cardContainer{
        width: 80%;
        height: 60%;
    }
    .CardsDiv{
      display: grid;
    /* width: 1000%; */
    margin-left: 40px;
    place-items: center;
    /* justify-content: center; */
    grid-template-columns: repeat(auto-fit, minmax(max(195px,30vw), 1fr));
  
    }
    .header{
        font-size: 16px;
        font-family: Montserrat;
    
        font-weight: bold;
        color: #ee487d;
        text-align: center;
        margin-bottom: 50px;
    }
  
  }
  
  
  .text1 {
    /* font-family: "Monsterrat";
    font-style: normal; */
    font-weight: bold;
    display: flex;
    font-size: 14px;
    line-height: 10px;
    align-items: end;
    justify-content: end;
  
/*   
    color: #ee487d; */
    padding: 0 0 0 15px;
  }
  
  