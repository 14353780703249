@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;1,400;1,700&display=swap");
body {
	overflow-x: hidden;
	zoom: 0.9;
	margin: 0 auto;
}
.vision-header {
	font-family: Outfit;
	margin-bottom: -50px;
	text-align: left;
	margin-left: 300px;
	margin-top: 200px;
}
.tech-header {
	font-family: Outfit;
	margin-bottom: -50px;
	text-align: left;
	margin-left: 680px;
	margin-top: 200px;
}
.serv-header {
	font-family: Outfit;
	margin-bottom: -50px;
	text-align: left;
	margin-left: 220px;
	margin-top: 200px;
}
.ourteam-header {
	font-family: Outfit;
	margin-bottom: -50px;
	text-align: center;
	margin-top: 200px;
}

@media (max-width: 768px) {
	.vision-header {
		margin-bottom: -35px;
		margin-left: 25px;
		margin-top: 100px;
	}
	.tech-header {
		margin-bottom: -35px;
		margin-left: 25px;
		margin-top: 100px;
	}
	.serv-header {
		margin-bottom: -35px;
		margin-left: 25px;
		margin-top: 100px;
	}
	.ourteam-header {
		margin-bottom: -35px;
		margin-left: 25px;
		margin-top: 100px;
	}
}
