.crew {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	column-gap: 10%;
	row-gap: 20px;
	width: 100%;
	margin-top: 65px;
	margin-bottom: 0px;
}

.member_box {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px;
	font-family: Montserrat;
	flex-wrap: wrap;
}
.member_img {
	width: 250px;
	height: 250px;
	border-radius: 20px; 
	object-fit: cover;
}
.member_img_1 {
	width: 250px;
	height: 250px;
	object-fit: cover;
	border: 3px;
	border-radius: 30px;
	border-style: solid;
	border-color: rgb(175, 175, 175);
}
.sathya_img {
	width: 250px;
	height: 250px;
	object-fit: cover;
	border: 3px;
	border-radius: 30px;
	border-style: solid;
	border-color: rgb(175, 175, 175);
}
.member_details {
	display: flex;
	align-items: center;
	margin-top: 10px;
	text-align: center;
}
.member_name {
	margin: 0;
	font-size: 18px;
	font-weight: normal;
}
.member_des {
	margin: 0;
	font-size: 14px;
	color: goldenrod;
	font-weight: 500;
}
.linkedin_logo {
	width: 10px;
	height: 10px;
}

@media (max-width: 768px) {
	.crew {
		flex-direction: column;
	}
}
