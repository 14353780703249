.sponsor_slider{
  margin: 1rem 0;
  width: 100%;
}

.sponsors_div{
  padding: 20px 40px;
}

.sponsors{
  background-color:white;
  padding-left: 5rem;
}

.sponsors > p {
  font-family: Outfit;
}

@media only screen and (max-width: 850px){
  .sponsors{
      padding: 10px;
  }
  
  .sponsers_div{
      padding: 15px 30px;
      max-width: 90vw !important;
  }
}