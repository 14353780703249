.Gallery-container {
  text-align: center;
  margin-bottom: 80px;
}

.Gallery-header {
  font-family: Outfit;
  margin-bottom: 20px;
}

.Galleryimage-container {
  min-width: 100px;
  max-width: max-content;
  height: min-content;
  margin: 0 auto;
  position: relative;
  /* box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); */
}

.Galleryimage-container img {
  display: block;
  object-fit: contain;
  height: 100%;
  margin: auto;
  max-height: 650px;
  /* min-width: 600px; */
  max-width: max-content;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .Galleryimage-container {
    box-shadow: none; /* Remove shadow on smaller screens */
  }
}
