.banner {
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: center;
	text-align: center;
	padding: 20px;
	margin-bottom: 15px;
}
.banner_text {
	font-family: Montserrat;
	text-align: left;
	width: 390px;
	margin-right: 10px;
}
.banner_header {
	color: #ee487d;
	font-size: xx-large;
	font-size: 32px;
}
.banner_desc {
	margin-top: -20px;
	font-size: 18px;
	line-height: 1.6;
}
.banner_buttons {
	display: flex;
	gap: 10px;
}
.button1 {
	padding: 10px;
	background-color: #ee487d;
	color: #fff;
	border: none;
	cursor: pointer;
	border-radius: 50px;
	font-family: Outfit;
	font-size: large;
	transition: 0.3s;
}
.button1:hover {
	background-color: #40113e;
	color: #ee487d;
	transition: 0.3s;
}
.button2 {
	padding: 10px;
	background-color: #fff;
	color: #ee487d;
	border-width: 1px;
	border-style: solid;
	border-color: #ee487d;
	cursor: pointer;
	border-radius: 50px;
	font-size: large;
	font-family: Outfit;
	transition: 0.3s;
}
.button2:hover {
	border-color: #40113e;
	color: #40113e;
	transition: 0.3s;
}
.button3 {
	padding: 10px;
	background-color: #fff;
	color: #ee487d;
	border-width: 1px;
	border-style: solid;
	border-color: #ee487d;
	cursor: pointer;
	border-radius: 50px;
	font-size: large;
	font-family: Outfit;
}
.banner_img {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.ban_img {
	width: 100%;
	max-width: 390px;
	height: auto;
	margin-bottom: 20px;
}

@media (max-width: 768px) {
	.banner {
		flex-direction: column-reverse;
		padding: 10px;
		margin-left: 25px;
		width: 390px;
	}

	.banner_img {
		margin-top: 20px;
		object-fit: contain;
		width: 350px;
		height: auto;
	}
}
