/* src/components/PartnerTable.css */

.partner-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
}

.partner-table th, .partner-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.partner-table th {
    background-color: #2a3f63;
    color: white;
}

.partner-table td {
    background-color: #f9f9f9;
}

.partner-table td i {
    font-style: italic;
}

.partner-table sup {
    font-size: 0.8em;
    vertical-align: super;
}
