.contactus-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 100px;
  }
  
  .form-container {
    width: 100%;
    height: 700px;
    max-width: 800px;
    padding: 20px;
  }

  .form{
    width: 600px;
    height: 700px;
  }
  
  .contact-container {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  
  .phone-number {
    margin-left: -100px;
    font-size: 18px;
    font-family: Montserrat;
  }
  
  .map {
    width: 100%;
    height: 200px; 
  }
  
  .social-icons {
    margin-left: -100px;
    display: flex;
    gap: 20px;
    font-size: 24px;
  }
  
  .social-icons a {
    color: #333;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color:darkgray ;
    transition: 0.3s;
  }
  
 
  @media (max-width: 768px) {
    .contactus-container {
        flex-direction: column-reverse;
        padding-left: 0;
        padding-right: 0;
        width: 390px;
    }
    .contact-container {
      max-width: 75%;
    }
    .form-container{
      width: 390px;
      margin-left: 40px;
    }
    .form{
      width: 390px;
    }
    .social-icons, .phone-number{
      margin-left: 25px;
    }
  
    .map {
      height: 200px; 
      width: 390px;
      margin-left: 35px;
    }
  }
  