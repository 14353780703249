.techbanner {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	text-align: center;
	padding: 20px;
	flex-wrap: wrap;
}

.techbanner-text {
	font-family: Montserrat;
	text-align: left;
	width: 400px;
	margin-right: 10px;
}
.techbanner-text h2 {
	color: #ee487d;
	font-size: xx-large;
	font-size: 60px;
}
.techbanner-text p {
	margin-top: -20px;
	font-size: 18px;
	line-height: 1.6;
}

.techbuttons {
	display: flex;
	gap: 10px;
}

.techbutton1 {
	padding: 10px;
	background-color: #ee487d;
	color: #fff;
	border: none;
	cursor: pointer;
	border-radius: 50px;
	font-family: Outfit;
	font-size: large;
	transition: 0.3s;
	text-transform: uppercase;
}

/* .techbutton1:hover{
    background-color: #40113e;
    color: #ee487d;
    transition: 0.3s;
  } */

.techbutton2 {
	padding: 10px;
	background-color: #fff;
	color: #ee487d;
	border-style: solid;
	border-color: #ee487d;
	border-width: 1px;
	cursor: pointer;
	border-radius: 50px;
	font-family: Outfit;
	font-size: large;
	transition: 0.3s;
	text-transform: uppercase;
}

.techimage-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.techimage-container img {
	width: 100%;
	max-width: 300px;
	height: 250px;
	margin-bottom: 20px;
	border-style: solid;
	color: lightgrey;
}

.techsmall-images {
	display: flex;
	justify-content: center;
	gap: 10px;
}

.techsmall-images img {
	width: 50px;
	height: 50px;
	cursor: pointer;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
	.techbanner {
		flex-direction: column-reverse;
		padding: 10px;
		width: 390px;
		margin-left: 17px;
	}

	.techimage-container {
		margin-top: 20px;
		object-fit: contain;
		width: 380px;
	}
	.techbanner-text {
		width: 380px;
	}
}
