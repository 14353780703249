  /* .features {
    display: flex;
    flex-direction: row;
    align-items: stretch; 
    justify-content: center;
    margin: auto;
    line-height: 1.6;
    height: 40vh;
    min-height: 40vh; 
    gap: 20px;
  }
  
  .features-item {
    margin: auto;
    border: 1px solid #EE487D;
    border-radius: 20px;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: start; 
    gap: 40px;
    width: 33%;
    height: 100%; 
  }
  
  .features-item-main{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  }

  .feat-desc {
    width: 90%;
  }
  
  .feat-logo {
    margin-left: -30px;
    width: 150px;
    height: auto;
  }

  .features p{
    font-family: Montserrat;
    font-size: medium;
    color:black ;
    text-align: left;
  }
  .features h1{
    font-family: Montserrat;
    font-size: large;
    font-weight: bold;
    color:black ;
    text-align: left;
    text-transform: uppercase;
  }
  .features h2{
    font-family: Montserrat;
    font-size: 50px;
    font-weight: bold;
    color:black ;
    text-align: left;
  }

  @media (max-width: 768px){
    .features-item-main{
        flex-direction: column;
        gap: 0px;
    }
    .features-item{
        width: auto;
    }
  } */

  .features {
    display: flex;
    flex-direction: row;
    align-items: stretch; /* Ensure items stretch to the same height */
    justify-content: center;
    margin: auto;
    margin-bottom: 5vh;
    line-height: 1.6;
    gap: 20px;
    height: auto; /* Dynamically fit the content */
    min-height: 40vh; /* Ensure a minimum height for larger screens */
  }
  
  .features-item {
    flex: 1; /* Allow equal distribution of space */
    border: 1px solid #EE487D;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: flex-start; /* Ensure content starts at the top */
    align-items: flex-start; /* Align content to the top-left */
    gap: 20px;
    width: 33%; /* Responsive width for desktop */
  }
  
  .features-item-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  }

  .feat-head {
    display: flex;
    align-items: center;
    gap: 20px;
    img {
      width: 10%;
      object-fit: scale-down;
    }
  }
  
  .feat-desc {
    flex: 1;
    width: auto; /* Adjust width dynamically */
  }
  
  .feat-logo {
    margin-left: -30px;
    width: 150px;
    height: auto;
  }
  
  .features p {
    font-family: Montserrat, sans-serif;
    font-size: medium;
    color: black;
    text-align: left;
  }
  
  .features h1 {
    font-family: Montserrat, sans-serif;
    font-size: large;
    font-weight: bold;
    color: black;
    text-align: left;
    text-transform: uppercase;
  }
  
  .features h2 {
    font-family: Montserrat, sans-serif;
    font-size: 50px;
    font-weight: bold;
    color: black;
    text-align: left;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .features {
      flex-direction: column; /* Stack items vertically */
      gap: 20px; /* Maintain spacing between items */
    }
  
    .features-item {
      width: 100%; /* Full width for smaller screens */
    }
  }  
  