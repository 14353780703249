/* General styling */
.Teamcontainer {
    width: 100%;
    display: flex;
    justify-content: center;
}

.crew {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-gap: 20px;
    justify-items: center;
    align-items: center;
    width: 80%;
    margin-top: 50px;
}

.member_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    gap: 10px;
}

.member_img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
    transition: transform 0.3s;
}

.member_box:hover .member_img {
    transform: scale(1.05); /* Slight zoom on hover */
}

.member_details {
    margin-top: 10px;
    line-height: 1.6;
}

.member_name {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
}

.member_des {
    font-size: 14px;
    color: goldenrod;
}

/* For very small screens (Mobile) */
@media (max-width: 768px) {
    .crew {
        grid-template-columns: 0fr 1fr 0fr;
        grid-gap: 10px;
    }

    .member_box {
        width: 100%; /* Make sure the member box takes full width */
    }

    .member_img {
        width: 120px;
        height: 120px;
    }

    .member_name {
        font-size: 10px;
    }

    .member_des {
        font-size: 10px;
    }
}

/* For tablet */
@media (min-width: 769px) and (max-width: 1024px) {
    .member_img {
        width: 150px;
        height: 150px;
    }

    .member_name {
        font-size: 16px;
    }

    .member_des {
        font-size: 13px;
    }
}

/* For desktop */
@media (min-width: 1025px) {
    .crew {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .member_img {
        width: 200px;
        height: 200px;
    }

    .member_name {
        font-size: 18px;
    }

    .member_des {
        font-size: 14px;
    }
}
