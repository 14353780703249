body{
    overflow-x: hidden;
}
.user-focused-design{
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 75%;
    height: auto;
    padding: 20px 0 20px 0;
    
}
.user-focused-design p{
    font-family: Outfit;
}
.user-focused-design h1{
    font-family: Montserrat;
    color: #ee487d;
}
.features-container{
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 75%;
    height: auto;
    padding: 20px 0 20px 0;
}
.features-container p{
    font-family: Outfit;
}


@media (max-width: 768px){
    .video-container{
        width: 390px;
        height: 200px;
        margin-left: 20px;
    }
    .user-focused-design{
        width: 390px;
        height: auto;
    }
    .features-container{
        width: 390px;
        height: auto;
    }


}