.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 10px;
    flex-wrap: wrap;
    padding-left: 0px;
    padding-right: 35px;
}

.navbar_left {
    align-items: center;
    justify-content: left;
    cursor: pointer;
}

.navlogo {
    width: 400px;
    height: 80px;
    object-fit: contain;
}

.navbar_right {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    font-family: Outfit;
    text-transform: uppercase;
    z-index: 9999;
}

.navbar_right a {
    text-decoration: none;
    color: inherit;
}

.navbar_right #li1 {
    margin-left: 35px;
}

.pages {
    cursor: pointer;
    transition: 0.3s;
}

.pages:hover {
    color: #ee487d;
    transition: 0.3s;
}

.contact_us_button {
    background-color: #ee487d;
    border-radius: 25px;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 15px;
    padding-right: 15px;
    border: none;
    cursor: pointer;
    color: azure;
    transition: 0.3s;
}

.contact_us_button:hover {
    background-color: #40113e;
    color: #ee487d;
    transition: 0.3s;
}

.contact_us_text {
    font-weight: lighter;
    font-size: medium;
    font-family: Outfit;
    text-transform: uppercase;
}

.hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
}

.line {
    width: 25px;
    height: 2px;
    background-color: #ee487d;
    margin: 4px;
    transition: transform 0.3s;
    border-radius: 50px;
}

.open .line:nth-child(1) {
    transform: translateY(10px) rotate(-45deg);
}

.open .line:nth-child(2) {
    opacity: 0;
}

.open .line:nth-child(3) {
    transform: translateY(-10px) rotate(45deg);
}

#li1 {
    color: #000000;
    font-size: 16px;
    font-family: "Outfit";
}

#li2 {
    color: white;
    font-size: 16px;
    font-family: "montserrat";
}

#para{
    margin: 16px;
    font-size: 12pt;
}

.c31 {
    max-width: 100%;
    padding: 0;
}

#contents {
    padding: 0px 5% 50px 7%;
}

/* Media query for mobile devices */
@media (max-width: 956px) {
    .navlogo {
        width: 300px;
    }

    .navbar_right {
        display: none;
        flex-direction: column;
        background-color: #fff;
        position: absolute;
        top: 100px;
        width: 100%;
        height: 100%;
        padding: 10px;
    }

    .navbar_right #li1 {
        align-items: center;
        justify-content: center;
        margin-top: 70px;
        margin-left: 0px;
        font-size: x-large;
    }

    .contact_us_text {
        font-size: x-large;
    }

    .contact_us_button {
        border-radius: 30px;
    }

    .navbar_right.open {
        display: flex;
    }


    .hamburger {
        display: flex;
    }

    .navbar_left {
        margin-left: 25px;
    }
}