  /* .container{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
  } */

  .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
    grid-template-rows: repeat(2, 1fr); /* 2 rows */
    gap: 10px; /* space between grid items */
    width: 100%;
    margin: auto;
  }

  .item {
    display: flex;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
  }

  .item-alternate {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    gap: 50px;
    width: 41%;
  }
  
  .logo {
    width: 15%;
    height: auto;
  }

  .container p{
    width:85%;
    font-family: Montserrat;
    font-size: large;
    font-weight: bold;
    color:rgb(0, 0, 74) ;
    text-align: center;
    background-color: rgb(221, 221, 221);
    border-radius: 50px;
    padding: 20px;
  }

  @media (max-width: 990px) {
    .container {
      display: flex;
      flex-direction: column;
    }
    .item{
      width: 80%;
    }
  }

  @media (max-width: 768px) {
    .item{
      width: 100%;
    }
  }
  
  
  