.techsec {
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	padding: 20px;
	column-gap: 40px;
	margin-bottom: 65px;
}

.techbanner-text {
	font-family: Montserrat;
	text-align: left;
	width: 500px;
}
.techbanner-text h7 {
	font-family: Outfit;
}
.techbanner-text h1 {
	margin-top: 0px;
	color: #ee487d;
	font-size: xx-large;
	font-size: 48px;
	width: 500px;
}
.techbanner-text p {
	margin-top: -20px;
	font-size: 18px;
	line-height: 1.6;
	width: 500px;
}

.tech-sub-desc {
	font-weight: bold;
}

.techbanner-text button {
	padding: 10px;
	background-color: #ee487d;
	color: #fff;
	border: none;
	cursor: pointer;
	border-radius: 50px;
	font-family: Outfit;
	font-size: large;
	transition: 0.3s;
	text-transform: uppercase;
}
.techbanner-text button:hover {
	background-color: #40113e;
	color: #ee487d;
	transition: 0.3s;
}

/* Styles for desktop devices */
@media (min-width: 768px) {
	.techsec {
		flex-direction: row;
	}

	.techsecimage-container {
		width: 500px;
		height: 500px;
	}
	.techsecimage-container img {
		object-fit: cover;
		width: 500px;
		height: auto;
	}
}

@media (max-width: 767px) {
	.techsec {
		margin-top: -30px;
		padding: 10px;
		margin-left: 10px;
		margin-bottom: -30px;
	}

	.techbanner-text {
		text-align: center;
		width: 390px;
	}

	.techbanner-text h1 {
		width: 390px;
	}

	.techbanner-text p {
		width: 390px;
	}

	.techsecimage-container {
		margin-top: 20px;
		object-fit: contain;
	}
	.techsecimage-container img {
		width: 390px;
		height: 390px;
	}
}
