.carousel-slide {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    text-align: center;
    padding: 20px;
  }
  .arrow-button-left {
    position: absolute;
    top: 50%;
    left: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 32px;
    color: #000000;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
    z-index: 1;
  }
  .arrow-button-right {
    position: absolute;
    right: 40px;
    top: 50%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 32px;
    color: #000000;
    opacity: 0.5;
    transition: opacity 0.2s ease-in-out;
  }

  .arrow-button-left:hover {
    opacity: 1;
  }

  .arrow-button-right:hover {
    opacity: 1;
  }

  .carousel-root {
    position: relative;
  }


  .banner-text {
    font-family: Montserrat;
    text-align: left;
    width: 500px;
  }
  .banner-text h2 {
    color: #ee487d;
    font-size: xx-large;
    font-size: 60px;
  }
  .banner-text p {
    margin-top: -20px;
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 70px;
  }
  .banner-text button{
    padding: 10px;
    background-color: #ee487d;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 50px;
    font-family: Outfit;
    font-size: large;
    transition: 0.3s;
    text-transform: uppercase;
  }

  .banner-text button:hover{
    background-color: #40113e;
    color: #ee487d;
    transition: 0.3s;
  }
  
  
  @media (min-width: 768px) {
    .carousel-slide {
      flex-direction: row;
      text-align: left;
    }
  
    .image-container {
      margin-left: 20px;
      width: 400px;
      height: 400px;
    }
    .image-container img {
        width: 100%;
        height: 400px;
        object-fit: contain;
    }
  }
  
  @media (max-width: 767px) {
    .carousel-slide {
      padding: 10px;
      margin-left: 13px;
    }
  
    .image-container {
      margin-top: 20px;
      width: 390px;
    }
    .banner-text{
      width: 390px;
    }

    .banner-text h2{
      font-size: 48px;
    }
  }

  @media (max-width: 956px){
    .arrow-button-left{
      top: auto;
      bottom: 3%;
      left: 25%;
    }
    .arrow-button-right{
      top: auto;
      bottom: 3%;
      right: 25%;
    }
  }
  