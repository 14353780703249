.Footer{
    width: 100%;
    background-color: #ee487d;
    color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow: hidden;
    max-height: fit-content;
}
.Footer_logo{
    display: block;  
    cursor: pointer;
}
.Logo_img{
    width: 400px;
    height: 80px;
    object-fit: contain;
}

.Footer_nav{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    font-family: Montserrat;
    align-items: flex-start;
    justify-content: space-between;
}
.Footer_column{
    flex: 1 0 10%; 
    margin: -10px 10px 10px 10px;
    text-align: left;
}
.footer_column_title {
    margin-top: 30px !important;
    margin-bottom: 20px !important;
}
.Footer_column a{
    text-decoration: none;
    color: inherit;
}
.Footer_column #li2{
    list-style: none;
    margin-bottom: 10px;
    margin-left: 50px;
    width: 70%;
}
.bold{
    font-weight: bold;
    text-transform: uppercase;
    font-family: Outfit;
}
.Footer_Details{
    cursor: pointer;
    line-height: 1.5;
    display: flex;
    align-items: center;
    gap: 15px;
}
.rights{
    text-align: center;
    font-family: Montserrat;
}

@media (max-width: 767px){
    .Footer{
        flex-direction: row;
    }
    .Footer_column #li2{
        font-size: 10px;
    }
    .Footer_column {
        flex: 1 0 30%;
        margin: -20px 10px 10px 10px;
    }
}
