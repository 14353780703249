.vision_container {
	font-family: Montserrat;
	text-align: center;
	padding: 10px;
	max-width: 800px;
	margin: 0 auto;
	margin-bottom: 15px;
}

.header-text {
	margin-top: 0px;
	color: #ee487d;
	font-size: xx-large;
	font-size: 48px;
	width: 800px;
	text-align: center;
	justify-content: center;
}
.vision_container a {
	font-family: Outfit;
	text-align: center;
}

.vision_container p {
	margin-top: -20px;
	font-size: 18px;
	line-height: 1.6;
	width: 800px;
}

.sub-desc {
	font-weight: bold;
}

.my-button {
	padding: 10px;
	background-color: #ee487d;
	color: #fff;
	border: none;
	cursor: pointer;
	border-radius: 50px;
	font-family: Outfit;
	font-size: large;
	transition: 0.3s;
	text-transform: uppercase;
}

.my-button:hover {
	background-color: #40113e;
	color: #ee487d;
	transition: 0.3s;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
	.vision_container {
		margin-top: 0;
		max-width: 500px;
		margin-bottom: 0;
		margin-left: 10px;
		text-align: left;
	}
	.header-text {
		width: 390px;
		text-align: left;
	}
	.vision_container p {
		width: 390px;
	}
}
